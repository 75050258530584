//
//** Footer
//

//== Footer Base
@mixin m-build-footer-default-base($layout) {
    //== General mode
    .m-footer {
        height: array-get($layout, self, height);
		min-height: array-get($layout, self, height);

		.m-footer__wrapper {
			height: 100%;
			position: relative;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}	

		.m-footer__copyright {
			font-size: array-get($layout, copyright, font-size);
			font-weight: array-get($layout, copyright, font-weight);
			color: array-get($layout, copyright, color);
		}

		//== Footer nav
		.m-footer__nav {
			> .m-nav__item {
				> .m-nav__link {
					.m-nav__link-icon {
						color: array-get($layout, nav, color, default);
					}

					.m-nav__link-text {
						color: array-get($layout, nav, font-size);
						color: array-get($layout, nav, color, default);
					}
				}

				&:hover {
					> .m-nav__link {
						.m-nav__link-icon {
							color: array-get($layout, nav, color, hover);
						}

						.m-nav__link-text {
							color: array-get($layout, nav, color, hover);
						}
					}
				}

				&:last-child {
					padding-right: 0;

					> .m-nav__link {
						padding-right: 0;

						> .m-nav__link-icon {
							width: auto;
							padding-right: 0px;
						}
					}
				}
			}	
		}
    }
    //== Minimal desktop
    @include minimal-desktop {
    	.m-footer {
    		.m-footer__nav {
    			.m-nav__item {
    				padding: 0 0 0 10px;

    				&:first-child {
    					padding-left: 0;	
    				}
    			}
    		}
    	}
    }

    //== Mobile & tablet mode
    @include tablet-and-mobile {
        .m-footer {
        	padding: array-get($layout, self, padding, mobile);
			height: auto;

			.m-stack__item {
				text-align: center;
				padding: 5px 0 5px 0;
			}

			.m-footer__nav {
				text-align: center;
				float: none;
				margin: 0 auto;

				.m-nav__item {
    				padding: 0 0 0 5px;
    			}
			}
		}
    }
}
//== Build Footer Base
@include m-build-footer-default-base( $m-config-footer );