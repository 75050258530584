//
//** Right Aside
//

//== Base markup
.m-aside-right {
	width: array-get($m-config-aside, aside-right, self, width);
	padding: array-get($m-config-aside, aside-right, self, padding, desktop);
	border-left: 1px solid array-get($m-config-aside, aside-right, self, border-color);
	border-right: 1px solid array-get($m-config-aside, aside-right, self, border-color);
}

// Desktop mode
@include desktop {
	.m-aside-right {
		background: array-get($m-config-base, body, wrapper, bg-color );
		border-bottom-right-radius: array-get($m-config-base, body, wrapper, border-radius);
	}
}

// Tablet and Mobile mode
@include tablet-and-mobile {
	.m-aside-right {
		border-radius: array-get($m-config-base, body, wrapper, border-radius);
		padding: array-get($m-config-aside, aside-right, self, padding, mobile);		
		width: 100%;	
		background: array-get($m-config-base, body, wrapper, bg-color );
		border: 1px solid array-get($m-config-aside, aside-right, self, border-color);
		margin: 20px 0 10px 0;
	}
}