//
//** Menus Settings
//

$m-header-fixed-zindex: array-get($m-config-header, header, self, fixed, zindex);
$m-aside-menu-dropdown-submenu-width: 245px;

$m-aside-menu-active-link-bg-color: darken(#F7F7F9, 2%);

$m-config-menu-aside: (
	// base parameters required to build the base menu
	base:	(
		class: m-aside-menu, // menu element class name
		parent-class: m-aside-left, // manu parent aside element class name
		mode: general, // menu mode: general or tablet-and-mobile
	),

	// customize the menu
	build: (
		// layout
		layout: (
			// default mode
			default: (
				// menu panel itself
				self: (
					padding: 12px 0 30px 0
				),

				// root item
				item: (
					// item itself
					self: (
						margin:	0
					),

					// item link
					link: (
						// link itself
						self: (
							height: 44px,				
							padding: 9px 40px 9px 40px
						),					

						// link icon
						icon:	(
							width: 35px,
							align: left,
							font-size: 1.4rem
						),		

						// link bullet
						bullet:	(
							self: (
								width: 15px,
							),

							dot: (
								size: 4px
							),

							line: (
								width: 5px,
								height: 1px
							)							
						),					

						// link text
						text:	(
							font-size: 1.02rem,
							font-weight: 400,
							font-transform:	initial
						),

						// link arrow
						arrow:	(
							width: 20px,
							align: right,
							transition: all 0.3s ease,
							font-size: 0.8rem
						),

						// link badge
						badge:	(
							padding: 0px 0px 0px 5px,
							align: right
						)
					),

					// item submenu
					submenu: (
						// submenu self
						self: (
							indent: 25px,
							margin:	0
						),

						// submenu item
						item: (
							// item itself
							self: (
								margin:	0
							),

							// item link
							link: (
								// link itself
								self: (
									height: 40px,		
									padding: 0 30px,
									padding-x: 30px
								),					

								// link icon
								icon:	(
									width: 35px,
									align: left,
									font-size: 1.35rem
								),		

								// link bullet
								bullet:	(
									self: (
										width: 20px,
									),
									dot: (
										size: 4px
									),
									line: (
										width: 5px,
										height: 1px
									)							
								),						

								// link text
								text:	(
									font-size: 1rem,
									font-weight: 400,
									font-transform:	initial
								),

								// link arrow
								arrow:	(
									width: 20px,
									align: right,
									transition: all 0.3s ease,
									font-size: 0.7rem
								),

								// link badge
								badge:	(
									padding: 0px 0px 0px 5px,
									align: right,	
								)
							)
						),

						// submenu section
						section: (
							self: (
								indent: 30px,
								padding: 0 25px,
								margin: 20px 0 0 0,
								height: 40px
							),

							text: (
								font-size: 0.8rem,
								font-weight: 300,
								font-transform: uppercase,
								letter-spacing: 0.3px
							),

							icon: (
								font-size: 1.1rem
							)
						),

						// submenu separator
						separator: (
							margin: 15px 0
						)
					),

					// arrow
					arrow: (
						// classic triangle arrow
						classic: (
							size: 10px, // arrow size
							offset: (  
								arrow: 10px,  // arrow offset
								dropdown: -5px // dropdown offset
							)
						),

						// smooth triangle arrow
						smooth: (
							top: 2px,
							right: -2px,
							margin: 0px -7px 0 0
						) 
					)
				),

				// root section
				section: (
					self: (
						padding: 0 27px 0 27px,
						margin: 20px 0 0 0,
						height: 40px
					),

					text: (
						font-size: 0.85rem,
						font-weight: 600,
						font-transform: uppercase,
						letter-spacing: 0.3px
					),

					icon: (					
						font-size: 1.1rem
					)
				),

				// root separator
				separator: (
					margin: 15px 0,
				)
			),

			// dropdown mode
			dropdown: (
				// dropdown menu item
				item: (
					// item itself
					self: (
						margin: 0, 
						zindex:	$m-header-fixed-zindex - 1,
					),

					// submenu
					submenu: (
						// submenu itself
						self: (
							// submenu base styles
							padding: 20px 0,
							width: $m-aside-menu-dropdown-submenu-width,
							parent-width: $m-aside-left-width,
							border-radius: 4px,

							// submenu animation
							animation: (
								offset: 10px
							),

							// vertical offset
							offset: (
								root: (
									default: -7px,
									up: -10px
								),
								inner: (
									default: -10px,
									up: -10px
								)								
							)
						),

						// submenu item
						item: (
							// item itself
							self: (
								margin:	0
							),

							// item link
							link: (
								// link itself
								self: (
									height: 40px,				
									padding: 7px 30px
								),					
								// link icon
								icon:	(
									width: 35px,
									align: left,
									font-size: 1.35rem
								),		
								// link bullet
								bullet:	(
									self: (
										width: 20px,
									),
									dot: (
										size: 4px
									),
									line: (
										width: 5px,
										height: 1px
									)							
								),						
								// link text
								text:	(
									font-size: 1rem,
									font-weight: 400,
									font-transform:	initial
								),

								// link arrow
								arrow:	(
									width: 20px,
									align: right,
									transition: all 0.3s ease,
									font-size: 0.7rem
								),

								// link badge
								badge:	(
									padding: 0px 0px 0px 5px,
									align: right,	
								)
							)
						),

						// submenu section
						section: (
							self: (
								margin: 20px 0 0 0,
								padding: 0 25px,
								height: 40px
							),
								
							text: (
								font-size: 0.8rem,
								font-weight: 300,
								font-transform: uppercase,
							),

							icon: (
								font-size: 1.1rem,
							)
						),

						// submenu separator
						separator: (
							margin: 15px 0,
						),

						// submenu arrow
						arrow: (
							// classic triangle arrow
							classic: (
								offset: (
									default: 12px,
									up: 20px
								),
								size: 10px,
								left: 0,
								margin: 0
							),

							// smooth triangle arrow
							smooth: (
								offset: (
									default: 10px,
									up: 20px
								),
								left: 1px,
								margin: 0
							) 
						)
					)
				),		
			)		
		),

		// skins
		skins: (
			// light skin
			light: (
				// default mode
				default: (
					// menu panel itself
					self: (
						bg-color: array-get($m-config-aside, aside-left, skins, dark, self, bg-color)
					),

					// root item
					item: (
						// item itself
						self: (
							bg-color: (
								default: null,
								open: null,
								expanded: null,
								active: null,
								hover: null
							)
						),

						// item link
						link: (
							// link itself
							self: (
								bg-color: (
									default: null,
									open: null,
									expanded: null,
									active: $m-aside-menu-active-link-bg-color,
									hover: null
								)
							),					

							// link icon
							icon: (
								font-color:	(
									default: #525672,
									open: #8c8ea4,
									expanded: #8c8ea4,
									active: get-brand-color(), 
									hover: #8c8ea4
								),
							),		

							// link bullet
							bullet:	(
								dot: (
									bg-color: (
										default: #bcbccf,
										open: get-brand-color(),
										expanded: get-brand-color(),
										active: get-brand-color(),
										hover: get-brand-color(),
									)
								),
								line: (
									bg-color: (
										default: #bcbccf,
										open: get-brand-color(),
										expanded: get-brand-color(),
										active: get-brand-color(),
										hover: get-brand-color(),			
									)
								)							
							),					

							// link text
							text:	(
								font-color:	(
									default: darken(#868aa8, 10%),
									open: get-brand-color(),
									expanded: get-brand-color(),
									active: get-brand-color(),
									hover: get-brand-color(),		
								)	
							),

							// link arrow
							arrow:	(
								font-color:	(
									default: #bcbccf,
									open: #bcbccf,
									expanded: #bcbccf,
									active: get-brand-color(),
									hover: #bcbccf
								)
							)
						),

						// item submenu
						submenu: (
							// submenu self
							self: (
								bg-color: (
									default: null,
									open: null,
									active: null,
									hover: null
								)
							),

							// submenu item
							item: (
								// item itself
								self: (
									bg-color: (
										default: null,
										open: null,
										active: null,
										hover: null
									)
								),

								// item link
								link: (
									// link itself
									self: (
										bg-color: (
											default: null,
											open: null,
											active: $m-aside-menu-active-link-bg-color,
											hover: null
										)
									),					

									// link icon
									icon:	(
										font-color:	(
											default: #525672,
											open: #8c8ea4,
											active: get-brand-color(),
											hover: #8c8ea4
										)
									),		

									// link bullet
									bullet:	(
										dot: (
											bg-color: (
												default: #bcbccf,
												open: get-brand-color(),
												expanded: get-brand-color(),
												active: get-brand-color(),
												hover: get-brand-color(),
											)
										),
										line: (
											bg-color: (
												default: #bcbccf,
												open: get-brand-color(),
												expanded: get-brand-color(),
												active: get-brand-color(),
												hover: get-brand-color(),			
											)
										)					
									),						

									// link text
									text: (
										font-color:	(
											default: #868aa8,
											open: get-brand-color(),
											expanded: get-brand-color(),
											active: get-brand-color(),
											hover: get-brand-color(),
										)	
									),

									// link arrow
									arrow:	(
										font-color:	(
											default: #bcbccf,
											open: #bcbccf,
											expanded: #bcbccf,
											active: get-brand-color(),
											hover: #bcbccf
										)
									)
								)
							),

							// submenu section
							section: (
								self: (
									indent: 30px,
									padding: 0 25px,
									margin: 20px 0 0 0,
									height: 40px
								),

								text: (
									font-color: #3F4052,
									font-size: 0.8rem,
									font-weight: 300,
									font-transform: uppercase
								),

								icon: (
									font-color: #424554,
									font-size: 1.1rem
								)
							),

							// submenu separator
							separator: (
								border: 1px solid rgba(#ffffff, 0.07),
								margin: 15px 0
							)
						)
					),

					// root section
					section: (
						text: (
							font-color: lighten(#464b66, 45%)
						),
						icon: (
							font-color: #424554
						)
					),

					// root separator
					separator: (
						border: 1px solid rgba(#ffffff, 0.07)
					)
				),

				// dropdown mode
				dropdown: (
					// dropdown menu item
					item: (
						// submenu
						submenu: (
							// submenu itself
							self: (
								// submenu base styles
								bg-color: #ffffff,
								box-shadow: get-shadow(light),
							),

							// submenu item
							item: (
								// item itself
								self: (
									bg-color: (
										default: null,
										open: null,
										active: null,
										hover: null
									)
								),
								// item link
								link: (
									// link itself
									self: (
										bg-color: (
											default: null,
											open: null,
											active: $m-aside-menu-active-link-bg-color,
											hover: null
										)
									),					

									// link icon
									icon:	(
										font-color:	(
											default: #5f627e,
											open: #8c8ea4,
											active: get-brand-color(),
											hover: #8c8ea4
										)
									),		

									// link bullet
									bullet:	(
										dot: (
											bg-color: (
												default: #525672,
												open: #525672,
												active: get-brand-color(),
												hover: #8c8ea4
											)
										),

										line: (
											bg-color:	(
												default: #525672,
												open: #8c8ea4,
												active: get-brand-color(),
												hover: #8c8ea4
											)
										)							
									),						

									// link text
									text:	(
										font-color:	(
											default: #717594,
											open: #868aa8,
											active: get-brand-color(),
											hover: #868aa8
										)	
									),
									// link arrow
									arrow:	(
										font-color:	(
											default: #525672,
											open: #8c8ea4,
											active: get-brand-color(),
											hover: #8c8ea4
										)
									)
								)
							),

							// submenu section
							section: (
								text: (
									font-color: lighten(#868aa8, 10%)
								),
								icon: (
									font-color: lighten(#868aa8, 12%)
								)
							),

							// submenu separator
							separator: (
								border: 1px solid rgba(#000000, 0.07)
							)
						)
					)		
				)	
			)
		)			
	)
);