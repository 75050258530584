//
//** Gmaps Plugin Customization
//
//

//== Mixins
@mixin m-build-component--gmaps-base() {
    // Spinner markup
    .m-gmaps {
        /* important!  bootstrap sets max-width on img to 100% which conflicts with google map canvas*/
        img {
            max-width: none; 
        }

        &.m-gmaps--static{
            > div {
                background-repeat: no-repeat ;
                background-position: 50% 50% ;
                display:block;
            }
        }
    }
}

//== Build

@include m-build-component--gmaps-base();