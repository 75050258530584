//
//** ion Range Slider Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--ion-range-slider-base() {
    .m-ion-range-slider {
        .irs {
            .irs-min,
            .irs-max,
            .irs-from,
            .irs-to,
            .irs-single {
                padding: 2px 5px 1px 5px;
            }
        }
    }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--ion-range-slider-base();