//
//** Override The Base Framework Config For This Demo
//

//== Example(reduce font size from 14px to 13px globally for desktop mode only)

//== The body element font settings
/**
$m--root-font: (
    size: (
        desktop: 13px,
        tablet: 13px,
        mobile: 13px
    ), 
    weight: 300, 
    color: (
        light: #333333, 
        dark: #efefef
    )
);
*/

//== Brand Color
$m--brand-color: #0476E9;
$m--brand-inverse-color: #ffffff;
$m--state-colors: (
  // metronic states
        brand: (
                base: $m--brand-color,
                inverse: $m--brand-inverse-color
        ),
        metal: (
                base: #CDCDCD,
                inverse: #ffffff
        ),
        light: (
                base: #ffffff,
                inverse: #282a3c
        ),
        accent: (
                base: #00c5dc,
                inverse: #ffffff
        ),
        focus: (
                base: #9816f4,
                inverse: #ffffff
        ),

  // bootstrap states
        primary: (
                base: #0476E9,
                inverse: #ffffff
        ),
        success: (
                base: #00B42D,
                inverse: #ffffff
        ),
        info: (
                base: #35A3F7,
                inverse: #ffffff
        ),
        warning: (
                base: #FF9901,
                inverse: #111111
        ),
        danger: (
                base: #f4516c,
                inverse: #ffffff
        )
);

//== General box shadows
$m--box-shadow-base-color: darken(#c5c2cc, 45%);

$m--font-families: (
        regular: (-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"),
        heading: (-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol")
);

$m--font-weights: (
        thin:  300,     // access to this font weight value calling get-font-weight function as get-font-weight(this) in SASS code
        normal: 400,    // access to this font weight value calling get-font-weight function as get-font-weight(normal) in SASS code
        bold: 500,      // access to this font weight value calling get-font-weight function as get-font-weight(bold) in SASS code
        bolder: 600,    // access to this font weight value calling get-font-weight function as get-font-weight(bolder) in SASS code
        boldest: 700,   // access to this font weight value calling get-font-weight function as get-font-weight(boldest) in SASS code
        boldest2: 800   // access to this font weight value calling get-font-weight function as get-font-weight(boldest2) in SASS code
);

$m--root-font: (
        size: (
                desktop: 14px,
                tablet: 13px,
                mobile: 13px
        ),
        weight: 400,
        color: (
                light: #333333,
                dark: #efefef
        )
);