//
//** List Widget 1 Component
//

//== Component Base
@mixin m-build-component--widget1-base() {
    .m-widget1 {  	
    	padding: 2.2rem;

        &.m-widget1--paddingless {
            padding: 0;
        }

    	.m-widget1__item {
    		padding: 1.1rem 0;

    		.m-widget1__title {
    			font-size: get-font-size(regular, '++');
    			font-weight: get-font-weight(bolder);
    			margin-bottom: 0;
    		}

    		.m-widget1__desc {
    			display: inline-block;
    			margin-top: 0.21rem;
    			font-size: get-font-size(regular);
    			font-weight: get-font-weight(normal);
    		}

    		.m-widget1__number {
    			font-size: get-font-size(regular, '++++');
    			font-weight: get-font-weight(boldest);
    		}

    		&:first-child {
    			padding-top: 0.8rem; 
    		}

    		&:last-child {
    			padding-bottom: 0.8rem; 
    		}
    	}
    } 
}

//== Component Skin
@mixin m-build-component--widget1-skin() {
    $skin: get-default-skin(); 
       
    .m-widget1__item {
    	.m-widget1__title {
    		color: get-color($skin, regular, '+');
    	}

    	.m-widget1__desc {
    		color: get-color($skin, regular, '----');
    	}

    	.m-widget1__number {
    		color: get-color($skin, regular, '-');
    	}

    	border-bottom: 0.07rem dashed get-color($skin, panel);

    	&:last-child {
    		border-bottom: 0;
    	}
    }     
}

//== Build Component Base
@include m-build-component--widget1-base();

//== Build Component - Light Skin
@include m-build-component--widget1-skin();