//
//** Aside Left
//

@mixin m-build-aside-left-base($layout) {
	//== Generak markup
	.m-aside-left {
		background-color: array-get($layout, self, default, bg-color, desktop);
		width: array-get($layout, self, default, width);	
	}

	@include desktop {		
		.m-aside-left {
			background: array-get($m-config-base, body, wrapper, bg-color );
			@include border-bottom-left-radius( array-get($m-config-base, body, wrapper, border-radius) );
		}
	}

	@include tablet-and-mobile {
		.m-aside-left {
			background-color: array-get($layout, self, default, bg-color, mobile);
		}
	}
}

//== Build Aside Left Base
@include m-build-aside-left-base( array-get($m-config-aside, aside-left ) );

//== Build Aside Left Offcanvas
@include m-generate-component--offcanvas(m-aside-left, tablet-and-mobile, array-get($m-config-aside, aside-left, self, offcanvas, tablet-and-mobile) ); 