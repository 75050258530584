//
//** Demo Code Component
//

.m-demo {
    background: #f7f7fa;
    margin-bottom: 20px;

    &.m-demo--last {
        margin-bottom: 0;
    }

    .m-demo__preview {
        background: white;
        border: 4px solid #f7f7fa;
        padding: 30px;

        &.m-demo__preview--btn {
            .btn {
                margin-right: 5px;
                margin-bottom: 15px;
            }
        }

        &.m-demo__preview--badge {
            .m-badge {
                margin-right: 5px;
                margin-bottom: 15px;
            }
        }
    }

    .m-demo__tools {
        position: relative;
    }

    .m-demo__btn {
        position: absolute;
        z-index: 100;
        top: -4px;
        right: 4px;
        padding: 2px 6px 2px 6px;       
        cursor: pointer;
        font-size: 0.7rem;
        font-weight: 400;
        text-transform: uppercase;
        @include border-radius(0 3px 3px 0);
        background-color: darken(#f7f7f9, 7%);

        &:hover {
            background-color: darken(#f7f7f9, 15%);
        }
    }
}

.m-demo-buttons { 
    .btn {
        margin-right: 5px;
        margin-bottom: 10px;
    }
}

.m-demo-dropdowns {
    .m-dropdown {
        margin-right: 5px;
        margin-bottom: 10px;
    }
}