//
//** Aside Menus
// Build left aside menu

@include m-generate--menu-ver( $m-config-menu-aside );

@include desktop {
	.m-aside-menu {
		.m-menu__nav {
			padding: 0 10px;

			.m-menu__item {	
				&.m-menu__item--active {
					> .m-menu__link  {
						border-radius: 30px;
					}
				}
			}
		}
	}
}

@include tablet-and-mobile {
	.m-aside-menu {
		background: #fff;

		.m-menu__nav {
			padding-top: 0;
		}
	}
}