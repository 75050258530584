//
//** Timeline 1 Component
//

//== Component Base
@mixin m-build-component--timeline-base() {
	.m-timeline-1{		  
	 	&.m-timeline-1--fixed {
			width: 80%; 
	    	margin: 0 auto 0 auto;
	    }

	    position: relative;	    	 	

		&:after {
		    content: '';
		    position: absolute;
		    width: 0.4rem;			   
		    top: 0;
		    bottom: 3rem;
		    left: 50%;
		    margin-left: -0.142rem;
		}

		.m-timeline-1__items {
		 	padding-bottom: 6rem;	 
		 	position: relative;	

		 	.m-timeline-1__marker {
		 		width: 1.43rem;
		 		height: 0.37rem;		 			
		 		border-radius: 0.36rem;
		 		position: absolute;
		 		left: 50%;
		 		transform: translate(-46%, 0);
		 	}		 		

			.m-timeline-1__item {				    
			    position: relative;
			    margin-left: 0; 
			    width: 50%;	
			    min-height: 3rem; 	 
			     
			    .m-timeline-1__item-circle {
			    	background: white; 
			    	width: 1.43rem;
			    	height: 1.43rem;				    		
			    	border-radius: 50%;
			    	display: flex;
			    	justify-content: center;
			    	align-items: center;				    		
			    	z-index: 1; 
			    	position: absolute;				    	 

			    	> div {				    		 
			    		border-radius: 50%;
			    		width: 0.4rem;
			    		height: 0.4rem;
			    	}
			    }

			    .m-timeline-1__item-time {
					position: absolute;
					display: inline-block;
					margin-top: -2.14rem;						 
			    	font-weight: get-font-weight(boldest);

			    	> span {
			    		padding-left: 5px;
				    }							 
				}	

				.m-timeline-1__item-content {						   	
					position: relative;
					border-radius: 1.07rem;						    
					padding: 2.14rem;							 					 							    		    						    		    

					.m-timeline-1__item-title {						    	
						font-size: get-font-size(regular, '+');
						font-weight: get-font-weight(bolder);

						& + .m-timeline-1__item-body {
							margin-top: 1.5rem;
						}
					}				

					.m-timeline-1__item-actions {				
						margin-top: 1.5rem;
					}			 

					.media {
						> img {
							width: 10rem;
							height: 8.6rem;
							border-radius: 0.71rem;
						}	
					}
				}				 

				&.m-timeline-1__item--left {
			    	left: 0; 
			    	padding-right: 2.85rem;	

			    	.m-timeline-1__item-circle {				    		 
			    		right: 0;
			    		transform: translate(54%, 0);
			    		top: 1.57rem;
			    	}

			    	.m-timeline-1__item-arrow {                        
						@include m--build-smooth-arrow-horizontal(left, default);
					    right: 2.85rem;
					    top: 0.86rem;						   					        
   						position: absolute; 
   						color: #F7F8FC; 		                                               
					} 

					.m-timeline-1__item-time {							
						right: 3.8rem;							 
					}			    				 
				}

			 	&.m-timeline-1__item--right {
			    	left: 50%;				    	 
			    	padding-left: 2.86rem;	
			    	top: -3.5rem;

			    	.m-timeline-1__item-circle {
			    		top: 1.55rem;
			    		left: 0;
			    		transform: translate(-46%, 0);
			    	}				    			    	

			    	.m-timeline-1__item-arrow {                        
					    @include m--build-smooth-arrow-horizontal(right, default);							 
			    		transform: translate(10%, 0);							 
						left: 2.86rem;
					    top: 0.86rem;						     					        
   						position: absolute;     						 						 		                                               
					}

					.m-timeline-1__item-time {							
						left: 3.57rem;				    		 						 
					}						 	
				}    

				&.m-timeline-1__item--first {
					top: 3.5rem;

					& + .m-timeline-1__item--right {
						top: -1rem;
					}
				}
			}
		}
	} 

	@include minimal-desktop-and-below {
		.m-timeline-1 {
			width: 96% !important; 

			&:after {
				left: 0.357rem;
				bottom: 0;
			}
				
			.m-timeline-1__items {
				margin-bottom: 2rem;
				padding: 0 0 2rem 0;

				.m-timeline-1__marker {
					left: 0;
	 				transform: translate(-18%, 0);		 				 
				}

				.m-timeline-1__item {
					width: 100%;
					top: 0 !important;
					margin-top: 5rem !important;

					.m-timeline-1__item-time {
						position: absolute;
						display: inline-block;
						margin-top: -2.14rem;
					}	

					&.m-timeline-1__item--left {	
						padding-left: 2.86rem;	
						padding-right:0;		

						.m-timeline-1__item-circle {
							left: -0.5rem;
							transform: translate(15%, 0);
							top: 1.65rem;
						}

						.m-timeline-1__item-arrow {   
							@include m--build-smooth-arrow-horizontal(right, default);							    	 							    	 
					    	left: 2.93rem;
					    	position: absolute;	
					    	top: 0.86rem;
					    	transform: translate(-2%, 0);						    	 			    	     							 						   						     			                                               
						} 

						.m-timeline-1__item-time {								 
							right: 100%;	
							left: 3.57rem;
						}								 			 
					}

					&.m-timeline-1__item--right {
						left: 0;							 						 	
						padding-left: 2.86rem;	
						padding-right: 0;			 				 

						.m-timeline-1__item-circle {
							left: -0.5rem;
							top: 1.65rem; 
							transform: translate(13%, 0);			    			 
						}

						.m-timeline-1__item-arrow {							 
							position: absolute;	
							left: 2.92rem;	
							top: 0.86rem;
							transform: translate(-1%, 0);			     								 													
						}	

						.m-timeline-1__item-time {								
							left: 3.93rem;					    									 
						}							 				
					}

					&.m-timeline-1__item--first {
						top: 4rem !important;
						margin-top: 0 !important;

						& + .m-timeline-1__item--right {
							margin-top: 8rem !important;   
						}
					}
				}				 	
			}
		} 
	}
} 

//== Component Skin
@mixin m-build-component--timeline-skin() {
	$skin: get-default-skin();
	
	.m-timeline-1 {
		&::after {
			background-color: #E6EAF5;
		}
		
		.m-timeline-1__items {
			.m-timeline-1__marker {
				background-color: #E6EAF5;
			}
			.m-timeline-1__item {
				.m-timeline-1__item-arrow  {
					color: #F7F8FC;
				}

				.m-timeline-1__item-content {
					background-color: #F7F8FC;

					.m-timeline-1__item-desc {
						color: get-color($skin, regular, '-');
					}		
				}					 
			}
		}
	}
}

//== Build Component Base
@include m-build-component--timeline-base();

//== Build Component Skin
@include m-build-component--timeline-skin();