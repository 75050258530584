//
//** Demo Config
//

//== Aside Left Global Settings
$m-aside-left-width: 235px;
$m-aside-left-width-minimize: 70px;   

//== Header Tabbed Menu Global Settings
$m-header-tabbed-menu-height: 56px;
$m-header-tabbed-menu-submenu-height: 70px;

//== Secondary brand color
$m-secondary-brand-color: #ff006c;
$m-secondary-brand-inverse-color: #FFF;
$m-secondary-brand-hover-color: darken($m-secondary-brand-color, 10%);
$m-secondary-brand-hover-color-alt: darken($m-secondary-brand-color, 15%);